import React from "react";
import {list, navbar, btn, jelloHorizontal} from './header-nav.module.css'

function HeaderNav() {
  return (
    <nav className={navbar}>
      <ul className={list}>
        <li><a href='#showroom'>Le Showroom</a></li>
        <li><a href='#chantier'>Nos Réalisations</a></li>
        <li className={`${btn} ${jelloHorizontal}`}><a href='#contact'>Nous Contacter</a></li>
      </ul>
    </nav>
  );
}

export default HeaderNav;
